.slide-enter .quotes-hadits{
   opacity: 0;
}

.slide-enter-active .quotes-hadits{
  opacity: 1;
  transition: opacity 2000ms;
}

.slide-exit .quotes-hadits{
 opacity: 0;
}

.slide-exit-active .quotes-hadits{
  opacity: 1;
  transition: opacity 2000ms;
}


