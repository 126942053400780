@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.lazy-load-image-background {
  display: unset !important;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet-active {
  border-radius: 12px !important;
  width: 32px !important;
  transition: 0.75s;
  background: #1aa5f2 !important;
}

.temporary-animation {
  -webkit-animation-iteration-count: 2;
  animation-iteration-count: 2;
}
